import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { AboutNav } from 'components/organisms/aboutNav';
import { AboutSection } from 'components/organisms/aboutSection';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import { Text } from 'components/atoms/text/';
import sizes from 'style/sizes';
import { mq, breakpoint } from 'style/variables';
import FadeUpItem from 'components/organisms/fadeUpItem';
import { graphql } from 'gatsby';
import SEO from 'util/seo';
import FadeUpSection from 'components/organisms/fadeUpSection';
import Img from 'components/atoms/img';
import { EnHeading } from 'components/atoms/enHeading';
import fonts from 'style/fonts';
import { text } from 'style/mixin';
import { ContentInner } from 'components/organisms/contentInner';
import { Button } from 'components/atoms/button';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

const MainText = styled.div`
  margin-top: 60px;
  ${mq.onlysp} {
    margin-top: ${sizes.margin[49]};
  }
  p {
    font-family: ${fonts.jaGothic};

    & + p {
      margin-top: 2em;
    }
    br {
      ${mq.onlysp} {
        display: none;
      }
    }
  }
`;

const AboutText = styled(Text)`
  ${text(15, 2.5)};
  ${mq.onlysp} {
    ${text(15, 2.2)};
  }
  & + & {
    margin-top: 3.7em;
    ${mq.onlysp} {
      margin-top: 3em;
    }
  }
`;

const CsvColsWrap = styled(ContentInner)`
  ${mq.onlypc} {
    margin-bottom: ${sizes.margin[180]};
  }
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[100]};
  }
`;

const CsvCard = styled.div`
  ${mq.onlypc} {
    display: flex;
    justify-content: space-between;
  }
  &:not(:first-of-type) {
    ${mq.onlypc} {
      margin-top: ${sizes.margin[180]};
    }
    ${mq.onlysp} {
      margin-top: ${sizes.margin[100]};
    }
  }
`;

const CsvCardImage = styled.div`
  ${mq.onlypc} {
    min-width: ${(428 / 938) * 100}%;
  }
  ${mq.onlysp} {
    margin: 0 -${sizes.margin[32]} ${sizes.margin[32]};
  }
`;

const CsvCardTexts = styled.div`
  ${mq.onlypc} {
    max-width: ${(460 / 938) * 100}%;
  }
`;

const CsvButton = styled(Button)`
  margin-top: 50px;
`;

const CsvCardHeading = styled(EnHeading)`
  font-weight: bold;
`;

const CsvCardText = styled(Text)`
  margin-top: ${sizes.margin[32]};
`;

const Pages: React.FC<IndexPageProps> = () => {
  const [isSp, setIsSp] = useState(false);

  const resizeHandler = useCallback(() => {
    setIsSp(window.innerWidth <= breakpoint);
  }, []);

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <>
      <SEO title={'Company | About'} />
      <ContentWrapper>
        <AboutNav
          currentPage="Sustainability"
          links={[
            { text: 'Company', link: '/en/about/company' },
            { text: 'Studios', link: '/en/about/studios' },
            { text: 'Vision', link: '/en/about/vision' },
            { text: 'Culture', link: '/en/about/culture' },
            { text: 'Sustainability', link: '/en/about/sustainability', active: true },
          ]}
          size="large"
        />
        <AboutSection>
          <FadeUpItem delay={0.6}>
            <EnHeading>Designing sustainability</EnHeading>
          </FadeUpItem>
          <FadeUpItem delay={0.8}>
            <MainText>
              <AboutText>
                At FOURDIGIT we base our work in sustainability on the idea of Creating Shared Value (CSV). An
                enterprise creates CSV when it simultaneously builds economic and social value by working to address
                social issues through its business activities. We design structures that enable sustainable growth,
                creating mutual economic benefits so as to share the value we provide with society at large.
              </AboutText>
            </MainText>
          </FadeUpItem>
        </AboutSection>

        <CsvColsWrap size="large" position="right">
          <FadeUpSection>
            <CsvCard>
              <CsvCardImage>
                <Img filename={'about/csv/img-mfs.jpg'} alt={'mfs'} />
              </CsvCardImage>
              <CsvCardTexts>
                <CsvCardHeading size="small">MOM FoR STAR</CsvCardHeading>
                <CsvCardText>
                  MOM FoR STAR is a web-design employment and collaboration project aimed at single mothers in Okinawa.
                  FOURDIGIT creates programs that function as entry points for working in IT by assembling learning
                  flows and operations, and breaking down the tasks to be undertaken. MOM FoR STAR supports upskilling
                  and career advancement and is attracting more collaborators to take on digital design.
                </CsvCardText>
                <CsvButton type={'fill'} href={'https://www.momforstar.jp/'} blank>
                  Visit Website
                </CsvButton>
              </CsvCardTexts>
            </CsvCard>
            <CsvCard>
              <CsvCardImage>
                <Img filename={'about/csv/img-vbpo.jpg'} alt={'vbpo'} />
              </CsvCardImage>
              <CsvCardTexts>
                <CsvCardHeading size="small">VBPO</CsvCardHeading>
                <CsvCardText>
                  Located in Danang, Vietnam, VBPO is an IT enterprise committed to employment and training for people
                  with disabilities. FOURDIGIT has been engaged with VBPO as a partner since 2018 and is working
                  together with them on some of their content operation projects.
                </CsvCardText>
              </CsvCardTexts>
            </CsvCard>
          </FadeUpSection>
        </CsvColsWrap>
      </ContentWrapper>

      <InquiryBlock en />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query AboutCsvEn {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
